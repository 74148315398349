import { action, extendObservable } from "mobx";
import { PropTypes as MobXTypes, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components";
import { scale } from "tonal";

const TonicSelect = styled(Select)`
  width: 110px;
  margin: 5px;

  & .Select-value,
  & .Select-option {
    text-align: center;
  }
`;

const ScaleSelect = styled(Select)`
  flex: 1;
  max-width: 300px;
  margin: 5px;
`;

const tonics = [
  { value: "A", label: "A" },
  { value: "A#", label: "A# / Bb" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "C#", label: "C# / Db" },
  { value: "D", label: "D" },
  { value: "D#", label: "D# / Eb" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "F#", label: "F# / Gb" },
  { value: "G", label: "G" },
  { value: "G#", label: "G# / Ab" },
];

const scales = scale.names(true).map((scaleName) => ({
  value: scaleName,
  label: scaleName,
}));

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Fretboard extends Component {
  constructor(props) {
    super(props);

    // Use extendObservable to define observable properties
    extendObservable(this, {
      tonic: "C",
      scale: "major",
      setTonic: action(this.setTonic),
      setScale: action(this.setScale),
      updateScale: action(this.updateScale),
    });

    this.updateScale();
  }

  static propTypes = {
    setScale: PropTypes.func.isRequired,
    scale: MobXTypes.arrayOrObservableArray.isRequired,
  };

  setTonic = (option) => {
    this.tonic = option.value;
    this.updateScale();
  };

  setScale = (option) => {
    this.scale = option.value;
    this.updateScale();
  };

  updateScale = () => {
    this.props.setScale(this.tonic, this.scale);
  };

  render() {
    return (
      <Container>
        <TonicSelect
          options={tonics}
          value={this.tonic}
          onChange={this.setTonic}
          clearable={false}
          searchable={false}
          backspaceRemoves={false}
          autosize={false}
          closeOnSelect={false}
        />
        <ScaleSelect
          options={scales}
          value={this.scale}
          onChange={this.setScale}
          clearable={false}
          backspaceRemoves={false}
          autosize={false}
          closeOnSelect={false}
          noResultsText="No scales found"
        />
      </Container>
    );
  }
}

export default observer(Fretboard);
