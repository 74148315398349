export default [
    null,
    null,
    1,
    null,
    1,
    null,
    1,
    null,
    1,
    null,
    null,
    2,
    null,
    null,
    1,
    null,
    1,
    null,
    1,
    null,
    1,
    null,
];
