import { action, extendObservable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import "react-select/dist/react-select.css";
import styled from "styled-components";
import { note, scale } from "tonal";
import Fretboard from "./Fretboard";
import ScaleSelector from "./ScaleSelector";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 60px;
  background: #333;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const AuthorLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

class App extends Component {
  constructor(props) {
    super(props);

    // MobX 3: Use extendObservable to declare observables
    extendObservable(this, {
      scale: [],
      setScale: action(this.setScale),
    });

    // Initialize the scale
    this.setScale("A", "major");
  }

  setScale = (tonic, name) => {
    this.scale = scale
      .notes(tonic, name)
      // Simplify scale.
      .map((scaleNote) =>
        note.pc(note.fromMidi(note.midi(`${scaleNote}4`), true))
      );
  };

  render() {
    return (
      <Container>
        <Header>
          <ScaleSelector scale={this.scale} setScale={this.setScale} />
          <Info>
            <AuthorLink href="https://rutgerschimmel.nl">
              Rutger Schimmel
            </AuthorLink>
          </Info>
        </Header>
        <Content>
          <Fretboard scale={this.scale} />
        </Content>
      </Container>
    );
  }
}

export default observer(App);
