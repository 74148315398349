import { action, extendObservable } from "mobx";
import { Model } from "mobx-spine";

export class String extends Model {
  constructor({ tuningKey }) {
    super();
    // Initialize observable properties
    extendObservable(this, {
      tuningKey: tuningKey || 1,
      transpose: action(this.transpose),
    });
  }

  transpose = (semitones) => {
    this.tuningKey = (((this.tuningKey + semitones) % 12) + 12) % 12;
  };
}
