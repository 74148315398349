import React, { Component } from "react";
import { PropTypes as MobXTypes } from "mobx-react";
import { observer } from "mobx-react";
import String from "../component/String";
import Inlay from "../component/Inlay";
import Button from "../component/Button";
import { String as StringModel } from "../store/String";
import styled from "styled-components";

const Container = styled.div`
  overflow-x: auto;
  padding: 20px 0;
`;

const Board = styled.div`
  display: inline-block;
`;

class Fretboard extends Component {
  static propTypes = {
    scale: MobXTypes.arrayOrObservableArray.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      strings: [],
    };

    // Add strings on initialization
    const initialStrings = [8, 3, 11, 6, 1, 8];
    initialStrings.forEach((string) => {
      this.handleAddString({
        tuningKey: string,
      });
    });
  }

  handleAddString = ({ tuningKey, position }) => {
    const string = new StringModel({
      tuningKey: tuningKey || 1,
    });
    const strings = this.state.strings;

    switch (position) {
      case "first":
        strings.unshift(string);
        break;
      case "last":
      default:
        strings.push(string);
        break;
    }

    this.setState({ strings });
  };

  handleRemoveString = (index) => {
    const strings = this.state.strings;
    strings.splice(index, 1);
    this.setState({ strings });
  };

  render() {
    return (
      <Container>
        <Board>
          <Inlay position="top">
            <Button
              bold
              onClick={() => this.handleAddString({ position: "first" })}
              type="button"
            >
              +
            </Button>
          </Inlay>
          {this.state.strings.map((string, index) => (
            <String
              key={string.cid}
              string={string}
              strings={this.state.strings}
              scale={this.props.scale}
              onRemoveString={() => this.handleRemoveString(index)}
            />
          ))}
          <Inlay position="bottom">
            <Button
              bold
              onClick={() => this.handleAddString({ position: "last" })}
              type="button"
            >
              +
            </Button>
          </Inlay>
        </Board>
      </Container>
    );
  }
}

export default observer(Fretboard);
